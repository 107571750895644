import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  Typography,
  CircularProgress,
  Box,
} from "@mui/material";

const RedirectingModal = ({ open, handleClose, duration = 5 }) => {
  const [timeLeft, setTimeLeft] = useState(duration);

  useEffect(() => {
    if (open && timeLeft > 0) {
      const timer = setInterval(() => setTimeLeft((prev) => prev - 1), 1000);
      return () => clearInterval(timer);
    } else if (timeLeft === 0) {
      handleClose();
    }
  }, [open, timeLeft, handleClose]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-paper": {
          width: "90%",
          maxWidth: "400px",
          borderRadius: "12px",
          fontFamily: "'Maven Pro', sans-serif",
          textAlign: "center",
          padding: "2rem",
        },
      }}>
      <DialogContent>
        {/* Spinner */}
        <CircularProgress
          sx={{
            color: "#00b894",
            marginBottom: "1rem",
          }}
        />
        {/* Title */}
        <Typography
          variant="h6"
          sx={{
            fontWeight: 600,
            fontSize: "18px",
            color: "#00b894",
            marginBottom: "0.5rem",
            fontFamily: "'Maven Pro', sans-serif",
          }}>
          Redirecting to Stripe
        </Typography>
        {/* Countdown Timer */}
        {/* <Typography
          sx={{
            fontSize: "14px",
            color: "#555",
            marginBottom: "1.5rem",
            fontFamily: "'Maven Pro', sans-serif",
          }}>
          Please wait... {timeLeft} seconds remaining
        </Typography> */}
        {/* Subtext */}
        <Typography
          sx={{
            fontSize: "12px",
            color: "#777",
            fontFamily: "'Maven Pro', sans-serif",
          }}>
          Do not refresh or close the page.
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default RedirectingModal;
