import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Popover,
  Box,
  Typography,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Collapse,
  IconButton,
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import { useSelector } from "react-redux";
import classes from "./TournamentDetail.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  Delete,
  PriceCheckRounded,
} from "@mui/icons-material";

import join from "../../assets/join-tournament.svg";
import {
  FaMapMarkerAlt,
  FaCity,
  FaGlobeAmericas,
  FaFutbol,
  FaCalendarAlt,
  FaCheckCircle,
} from "react-icons/fa";
import { GiMoneyStack } from "react-icons/gi";

import ProgressLoader from "../../Components/ProgressLoader";
import { joinTournament } from "../../Api/services/tournament";
import PageTitleHeader from "../../Components/Header/PageTitleHeader";
import ScrollToTop from "../../hooks/ScrollToTop";
import { getGoogleMapsLink } from "../../Api/services/googleApi";
import { stringAvatar } from "../../Utils";
import { CImage, CButton } from "@coreui/react";
import PlayerSelector from "../../Components/PlayerSearch";
import { Group, Person } from "@mui/icons-material";
import { toast } from "react-toastify";
import RedirectingModal from "../CreateMatch/RedirectModal";

const TournamentDetail = () => {
  const [joining, setJoining] = useState(false);
  const [openRowIndex, setOpenRowIndex] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [teamId, setTeamId] = useState();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [error, setError] = useState(false);
  const [joined, setJoined] = useState(false);
  const dropdownRef = useRef(null);

  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(true);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [formData, setFormData] = useState({
    players: [],
  });
  const [addedPlayers, setAddedPlayers] = useState(formData?.players || []);
  const handleAddPlayer = (player) => {
    if (!player.value) {
      setFormData({
        ...formData,
        players: [...formData?.players, player],
      });
      setAddedPlayers([...addedPlayers, player]);
      setIsAddButtonDisabled(true);
      setSelectedPlayer(null);
    } else {
      if (!addedPlayers?.some((p) => p.value === player?.value)) {
        setFormData({
          ...formData,
          players: [...formData?.players, player],
        });
        setAddedPlayers([...addedPlayers, player]);
        setIsAddButtonDisabled(true);
        setSelectedPlayer(null);
      }
    }
  };
  const removeInvitedPlayer = (player) => {
    let players = [...(formData?.players || [])];
    let updatedPlayerList;

    if (player?.value) {
      updatedPlayerList = players.filter((p) => p?.value !== player.value);
    } else if (player?.label) {
      updatedPlayerList = players.filter((p) => p?.label !== player.label);
    } else {
      updatedPlayerList = players;
    }

    setFormData({ ...formData, players: updatedPlayerList });
  };

  const user = useSelector((state) => state?.user);
  const location = useLocation();
  const data = location.state?.data;

  const filteredTeams = data.teams.filter((team) =>
    team?.teamName?.toLowerCase()?.includes(searchTerm?.toLowerCase())
  );

  const initialTeams = filteredTeams.slice(0, 5);

  const getJoinedValue = () => {
    const res = data?.players.filter((player) => {
      return player.playerId == user?._id;
    });
    if (res.length > 0) {
      setJoined(true);
    }
  };
  useEffect(() => {
    getJoinedValue();
  }, [user]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleTeamSelect = (teamName) => {
    setSearchTerm(teamName);
    const team = data?.teams?.find(
      (team) => team.teamName.toLowerCase() === teamName.toLowerCase()
    );
    setTeamId(team._id);
    setIsDropdownOpen(false);
  };

  const handleJoin = async () => {
    setError(false);
    setJoining(true);
    if (!teamId) {
      setJoining(false);
      setError(true);
      return null;
    } else {
      const body = {
        tournamentId: data?._id,
        success_url: `https://app.kicks.co/joined/${data?._id}?teamId=${teamId}`,
        cancel_url: `https://app.kicks.co/dashboard?tab=tournament`,
        slot: {
          start: data?.slot?.start,
          end: data?.slot?.end,
        },
        userId: user?._id,
        // teamId: teamId,
        players: addedPlayers,
        paymentGroup: selectedOption,
      };
      try {
        let res = await joinTournament(body);
        setModalOpen(true);
        if (res) {
          setTimeout(() => {
            let a = document.createElement("a");
            a.href = res?.session.url;
            a.click();
            setModalOpen(false);
          }, 5000); // Redirect after 5 seconds
        }
      } catch (err) {
        console.log("err", err);
      }
      setJoining(false);
    }
  };
  const [openRound, setOpenRound] = useState(null);

  const handleToggleRound = (roundIndex) => {
    setOpenRound(openRound === roundIndex ? null : roundIndex);
  };

  const roundNames = ["Knockout", "Quarterfinal", "Semifinal", "Final"];

  const getMatchesForRound = (roundIndex) => {
    const matchesPerRound =
      roundIndex === 0 ? 8 : roundIndex === 1 ? 4 : roundIndex === 2 ? 2 : 1;
    const start =
      roundIndex === 0 ? 0 : roundIndex === 1 ? 8 : roundIndex === 2 ? 12 : 14;
    const end = start + matchesPerRound;
    return data?.bookings?.slice(start, end);
  };

  const [open, setOpen] = useState(false); // For controlling Dialog
  const [selectedOption, setSelectedOption] = useState("individual"); // Default selected option
  const navigate = useNavigate();
  // Open the Dialog
  const handleClick = () => {
    if (user?.banStatus === "active") {
      setOpen(true);
    } else {
      navigate("/dashboard?tab=profile");
    }
  };

  // Close the Dialog
  const handleClose = () => {
    setOpen(false);
  };

  // Handle Option Select
  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };

  console.log("tournament", data);
  return (
    <div>
      <ScrollToTop />
      <PageTitleHeader title="Tournament Details" />

      <div className={classes.tournament_detail_container}>
        <div className={classes.heading_container}>
          <h2 className={classes.heading}>{data?.title}</h2>
          {data?.pitch?.facilities?.length > 0 && (
            <div className={classes.facilities_container}>
              {data.pitch.facilities.map((item, index) => (
                <span key={index} className={classes.facility_badge}>
                  {item}
                </span>
              ))}
            </div>
          )}
        </div>

        <Paper className={classes.details_card}>
          <h2 className={classes.section_title}>
            Pitch: {data?.pitch?.pitchName || "Pitch Name"}
          </h2>

          <div className={classes.details_grid}>
            <div className={classes.detail_item}>
              <FaMapMarkerAlt className={classes.icon} />
              <div>
                <strong>Address:</strong>
                <a
                  href={getGoogleMapsLink(
                    data?.pitch?.address?.line?.latitude || "N/A",
                    data?.pitch?.address?.line?.longitude || "N/A"
                  )}
                  className={classes.address_link}
                  target="_blank"
                  rel="noopener noreferrer">
                  {data?.pitch?.address?.street +
                    " " +
                    data?.pitch?.address?.city +
                    " " +
                    data?.pitch?.address?.country || "N/A"}
                </a>
              </div>
            </div>

            <div className={classes.detail_item}>
              <FaFutbol className={classes.icon} />
              <div>
                <strong>Pitch Type:</strong>
                <span>{data?.pitch?.pitchType || "N/A"}</span>
              </div>
            </div>
            <div className={classes.detail_item}>
              <FaCalendarAlt className={classes.icon} />
              <div>
                <strong>Start Date:</strong>
                <span>
                  {data?.startDate?.split("T")[0] || "N/A"}
                  <span>
                    {new Date(
                      data?.bookings[0]?.slot?.start
                    ).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </span>
                </span>
              </div>
            </div>
            <div className={classes.detail_item}>
              <FaCalendarAlt className={classes.icon} />
              <div>
                <strong>End Date:</strong>
                <span>
                  {data?.bookings[
                    data?.bookings.length - 1
                  ]?.slot?.start?.split("T")[0] || "N/A"}
                  <span>
                    {new Date(
                      data?.bookings[data?.bookings.length - 1]?.slot?.start
                    ).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </span>
                </span>
              </div>
            </div>
            <div className={classes.detail_item}>
              <GiMoneyStack className={classes.icon} />
              <div>
                <strong>Price:</strong>
                <span>
                  AED {data?.pricing?.pricePerPlayer + " Per Head" || "N/A"}
                </span>
              </div>
            </div>
          </div>
        </Paper>

        {joined && (
          <Paper className={classes.details_card}>
            <h2 className={classes.section_title}>Fixtures</h2>
            <Paper
              className={classes.paper1}
              sx={{ boxShadow: "0px 0px 6px 0px #00000026" }}>
              {roundNames.map((round, roundIndex) => {
                const matches = getMatchesForRound(roundIndex);
                return (
                  <Accordion
                    className={classes.accordion}
                    key={roundIndex}
                    expanded={openRound === roundIndex}
                    sx={{ boxShadow: "none" }}
                    onChange={() => handleToggleRound(roundIndex)}>
                    <AccordionSummary
                      className={classes.accordionSummary}
                      expandIcon={
                        openRound === roundIndex ? (
                          <KeyboardArrowUp />
                        ) : (
                          <KeyboardArrowDown />
                        )
                      }
                      aria-controls={`panel${roundIndex}-content`}
                      id={`panel${roundIndex}-header`}>
                      <h4 className={classes.roundTitle1}>{round}</h4>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                      {matches?.map((match, index) => (
                        <div className={classes.matchCard} key={index}>
                          <div className={classes.matchHeader}>
                            <div className={classes.teams}>
                              <span>{match?.teams[0]?.teamName || "TBD"}</span>
                              <span>vs</span>
                              <span>{match?.teams[1]?.teamName || "TBD"}</span>
                            </div>
                            <div className={classes.matchTime}>
                              <span>
                                {new Date(
                                  match?.slot?.start
                                ).toLocaleDateString()}{" "}
                              </span>
                              <span>
                                {new Date(
                                  match?.slot?.start
                                ).toLocaleTimeString([], {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                })}
                              </span>
                            </div>
                          </div>
                          <div className={classes.matchResult}>
                            {match?.teams[0]?.status === "Eliminated"
                              ? `Winner is ${match?.teams[1]?.teamName}`
                              : match?.teams[0]?.status === "Advanced"
                              ? `Winner is ${match?.teams[0]?.teamName}`
                              : "Winner yet to be decided"}
                          </div>
                        </div>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </Paper>
          </Paper>
        )}

        {!joined && new Date(data.startDate) > new Date() && (
          <Paper className={classes.join_section}>
            <h2 className={classes.section_title}>Join Tournament</h2>

            <div className={classes.join_form}>
              {/* <div className={classes.form_group}>
                <h6 className={classes.form_label}>Select Your Team</h6>
                <div className={classes.search_wrapper}>
                  <input
                    type="text"
                    placeholder="Search teams..."
                    value={searchTerm}
                    onChange={(e) => {
                      setError(false);
                      setTeamId(null);
                      setSearchTerm(e.target.value);
                    }}
                    onFocus={() => setIsDropdownOpen(true)}
                    className={classes.search_input}
                  />
                  {error && (
                    <p className={classes.error_text}>Please select a team</p>
                  )}
                </div>
              </div> */}

              <div className={classes.form_group}>
                <div className={classes.search_dropdown} ref={dropdownRef}>
                  <h6 className={classes.ip_label}>Select Your Team</h6>
                  <input
                    type="text"
                    placeholder="Search teams..."
                    value={searchTerm}
                    onChange={(e) => {
                      setError(false);
                      setTeamId(null);
                      setSearchTerm(e.target.value);
                    }}
                    onFocus={() => setIsDropdownOpen(true)} // Open dropdown on focus
                    className={classes.search_input}
                  />
                  {error ? (
                    <p
                      style={{ color: "red", fontSize: "14px", margin: "0px" }}>
                      Please select a team
                    </p>
                  ) : null}
                  {isDropdownOpen && (
                    <div className={classes.dropdown_menu}>
                      {filteredTeams?.length === 0 ? (
                        <p>No teams found</p>
                      ) : (
                        (searchTerm ? filteredTeams : initialTeams)?.map(
                          (team, index) => (
                            <div
                              key={index}
                              className={classes.dropdown_item}
                              onClick={() => handleTeamSelect(team.teamName)}>
                              {team.teamName}
                            </div>
                          )
                        )
                      )}
                    </div>
                  )}
                </div>
              </div>

              <div className={classes.form_group}>
                <h6 className={classes.form_label}>Add Friends To Play With</h6>
                <PlayerSelector
                  ActionButtonText={"Add"}
                  onAction={(player) => {
                    handleAddPlayer(player);
                  }}
                  onPlayerSelect={(player) => {
                    setSelectedPlayer(player);
                  }}
                  val={1}
                />
              </div>

              <div className={classes.form_group}>
                <h6 className={classes.form_label}>Invited Players:</h6>
                {formData?.players?.length > 0 && (
                  <div
                    style={{
                      maxHeight: "300px",
                      overflowY: "auto",
                      backgroundColor: "#f5f5f5",
                      padding: "8px",
                      boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.25)",
                      borderRadius: "4px",
                      margin: "8px 0px",
                    }}>
                    {formData?.players?.map((player, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          backgroundColor: "white",
                          boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.25)",
                          padding: "12px",
                          borderRadius: "4px",
                          margin: "12px",
                        }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}>
                          {player && player?.image ? (
                            <CImage
                              src={player?.image}
                              className={classes.image}
                            />
                          ) : (
                            <Avatar
                              {...stringAvatar(player?.label, "36px", "18px")}
                            />
                          )}
                          <span style={{ fontSize: 14, marginLeft: "8px" }}>
                            {player?.label}
                          </span>
                        </div>
                        <Delete
                          fontSize="small"
                          color="error"
                          onClick={() => removeInvitedPlayer(player)}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <button className={classes.create_btn} onClick={handleClick}>
                {joining ? (
                  <ProgressLoader />
                ) : (
                  <div className={classes.create_btn_content}>
                    <img src={join} alt="join" />
                    <span>Join Tournament</span>
                  </div>
                )}
              </button>
            </div>
          </Paper>
        )}
      </div>

      <RedirectingModal open={modalOpen} onClose={() => setModalOpen(false)} />
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-paper": {
            width: "90%",
            maxWidth: "500px", // Adjust the maximum width
            maxHeight: "80vh", // Adjust maximum height
            borderRadius: "12px",
          },
        }}>
        <DialogTitle
          sx={{
            textAlign: "center",
            fontSize: "1.25rem",
            fontWeight: 600,
            color: "#00b894",
            marginBottom: "1rem", // Adjust spacing between title and content
          }}>
          Select Payment Option
        </DialogTitle>

        <DialogContent sx={{ padding: "1.5rem", overflow: "auto" }}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap={3}
            sx={{ paddingBottom: "1.5rem" }} // Adds spacing between last element and button
          >
            {/* Pay for Entire Team Option */}
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              border="1px solid #00b894"
              padding={3}
              width="100%"
              borderRadius="8px"
              onClick={() => handleOptionSelect("all")}
              sx={{
                cursor: "pointer",
                transition: "0.3s",
                "&:hover": {
                  backgroundColor: "#e8f8f5",
                  boxShadow: "0 4px 8px rgba(0, 183, 148, 0.3)",
                },
                backgroundColor: selectedOption === "all" ? "#e8f8f5" : "white",
              }}>
              <Group sx={{ fontSize: 36, color: "#00b894" }} />
              <Typography
                variant="h6"
                sx={{ fontSize: "14px", marginLeft: "8px", color: "#333" }}>
                Pay for entire team
              </Typography>
            </Box>

            {/* Pay for Yourself Option */}
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              border="1px solid #00b894"
              padding={3}
              width="100%"
              borderRadius="8px"
              onClick={() => handleOptionSelect("individual")}
              sx={{
                cursor: "pointer",
                transition: "0.3s",
                "&:hover": {
                  backgroundColor: "#e8f8f5",
                  boxShadow: "0 4px 8px rgba(0, 183, 148, 0.3)",
                },
                backgroundColor:
                  selectedOption === "individual" ? "#e8f8f5" : "white",
              }}>
              <Person sx={{ fontSize: 36, color: "#00b894" }} />
              <Typography
                variant="h6"
                sx={{ fontSize: "14px", marginLeft: "8px", color: "#333" }}>
                Pay for yourself
              </Typography>
            </Box>

            {/* Proceed to Pay Button */}
            <Button
              variant="contained"
              onClick={() => handleJoin()}
              sx={{
                backgroundColor: "#00b894",
                color: "white",
                fontWeight: 600,
                padding: "12px 24px",
                borderRadius: "30px",
                width: "100%",
                "&:hover": {
                  backgroundColor: "#009b83",
                },
              }}>
              Proceed To Pay
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default TournamentDetail;
